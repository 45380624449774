import React from "react"
import Layout from "../components/layout"
import ArticleCard from "../components/articlecard/articlecard"
import { Container, Row, Col, Navbar, NavbarBrand } from 'reactstrap'
import Newsletter from "../components/newsletter/newsletter"
import Helmet from 'react-helmet'
import AdSense from 'react-adsense';
class ArticleTemplate extends React.Component {


    render() {

        let { article, relatedArticles } = this.props.pageContext;

        let c = 0;
        let relatedArticleList = relatedArticles.map((a) => {
            if (c > 1 || article.headline === a.headline) return null;
            c++;
            return (
                <div key={a.slug} style={{ padding: "10px" }}><a href={"/articles/" + a.slug}>                    
                    {a.headline}</a>
                </div>
            )
        })        

        return (
            <Layout>
                <Helmet
                    title={article.headline}
                    meta={[
                        { name: 'description', content: article.articleHtml.replace(/(<([^>]+)>)/gi, "") },
                    ]}
                />
                <div className="wrapper">

                    <Container>

                        <Row>
                            <Col>
                                <Newsletter cta="Like this article?  Keep up with anything new and subscribe to our newsletter!" />
                            </Col>
                        </Row>

                        <Row style={{ marginTop: "25px" }}>
                            <Col md={8} style={{marginBottom: "25px"}}>
                                <ArticleCard article={article} />
                                <div style={{backgroundColor: "white", padding: "0px 5% 20px 5%"}}>
                                    <h5>Other Articles You May Be Interested In</h5>
                                    {relatedArticleList}                                
                                </div>
                                
                            </Col>

                            <Col>

                                <div style={{ backgroundColor: "white" }}>

                                <Navbar color="dark" dark>
                                    <NavbarBrand className="mr-auto" style={{ color: "white", whiteSpace: "normal" }}>Check Out Our Softball Map</NavbarBrand>
                                </Navbar>                                
                                                                        
                                    <a href="/softball-map">
                                        <img src="/images/softball-map.jpg" alt="Softball Map" style={{width:"100%"}} />
                                    </a>
                                              
                                </div>



                                <div style={{marginTop: "25px"}}>
                                <AdSense.Google
                                    client='ca-pub-7485372285264586'
                                    slot='3804319691'
                                    style={{ display: 'block', margin: "0 auto", width: "100%", height: "auto" }}
                                    format='auto'
                                    responsive='true'
                                />
                                </div>
                            </Col>

                        </Row>


                        
                        
                    </Container>
                </div>
            </Layout>
        )
    }


}

export default ArticleTemplate